@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  min-height: 100vh;
}

html {
  scroll-behavior: smooth;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
}
