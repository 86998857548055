@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Calendly specific styles */
.calendly-inline-widget {
  position: relative !important;
  min-width: 320px !important;
  height: 100% !important;
}

.calendly-overlay {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.7) !important;
  z-index: 9999 !important;
}

.calendly-popup {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 90% !important;
  height: 85vh !important;
  max-width: 1000px !important;
  background: white !important;
  border-radius: 8px !important;
  z-index: 10000 !important;
  overflow: visible !important; /* Changed to visible to show the close button */
  padding: 0 !important;
}

.calendly-popup iframe {
  width: 100% !important;
  height: 100% !important;
  border: none !important;
}

.calendly-popup-content {
  height: 100% !important;
  width: 100% !important;
}

.calendly-popup-close {
  position: fixed !important;
  top: 20px !important;
  right: 20px !important;
  width: 40px !important;
  height: 40px !important;
  background: white !important;
  border-radius: 50% !important;
  border: none !important;
  cursor: pointer !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2) !important;
  z-index: 999999 !important;
  transition: all 0.2s ease !important;
  padding: 0 !important;
  margin: 0 !important;
}

.calendly-popup-close .fas {
  font-size: 20px !important;
  color: #333 !important;
}

.calendly-popup-close:hover {
  transform: scale(1.1) !important;
  background: #f8f8f8 !important;
}

.calendly-popup-close:hover .fas {
  color: #666 !important;
}

/* Industry Card Styles */
.industry-card {
  height: 100% !important;
  min-height: 240px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  padding: 1.5rem !important;
}

.industry-card-icon {
  margin-bottom: 1rem !important;
}

.industry-card-title {
  margin-bottom: 1rem !important;
  font-weight: 600 !important;
}

.industry-card-description {
  flex-grow: 1 !important;
}

.calendly-close-indicator {
  position: fixed !important;
  top: 10px !important;
  right: 10px !important;
  width: 40px !important;
  height: 40px !important;
  background: white !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
  z-index: 100001 !important; /* Ensure this is higher than Calendly's modal */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2) !important;
  font-size: 18px !important;
  font-weight: bold !important;
  color: #333 !important;
  line-height: 1 !important;
  border: 1px solid #ddd !important; /* Optional: To make the button more defined */
}

.calendly-close-indicator::before {
  content: "×"; /* Unicode for the 'X' symbol */
  font-size: 24px !important; /* Adjust font size for better visibility */
  font-weight: bold !important;
  color: #333 !important;
}

.calendly-close-indicator:hover {
  transform: scale(1.1) !important;
  background: #f8f8f8 !important;
}

.calendly-close-indicator:hover::before {
  color: #666 !important;
}
